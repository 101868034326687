import { Component, OnInit } from '@angular/core';
import { faHeart, faScroll, faUsers, faMap } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  faHeart = faHeart;
  faScroll = faScroll;
  faUsers = faUsers;
  faMap = faMap;

  slides = [
    { img: '/assets/images/slideshow/rotate1.jpg' },
    { img: '/assets/images/slideshow/rotate2.jpg' },
    { img: '/assets/images/slideshow/rotate3.jpg' },
    { img: '/assets/images/slideshow/rotate4.jpg' },
    { img: '/assets/images/slideshow/rotate5.jpg' },
    { img: '/assets/images/slideshow/rotate6.jpg' },
    { img: '/assets/images/slideshow/rotate7.jpg' },
    { img: '/assets/images/slideshow/rotate8.jpg' },
    { img: '/assets/images/slideshow/rotate9.jpg' },
    { img: '/assets/images/slideshow/rotate10.jpg' }
  ];

  slideConfig = {
    centerMode: true,
    slidesToShow: 3,
    autoplay: true,
    focusOnSelect: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 550,
        settings: {
          arrows: false,
          slidesToShow: 1
        }
      }
    ]
  };

  constructor() { }

  ngOnInit() {
  }

}
