import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  staffMembers = [
    {
      img: '/assets/images/people/board-members/Jenna-Sistad.jpg',
      name: 'Jenna Sistad',
      title: 'Executive Director'
    },
    {
      img: '/assets/images/people/staff/carlyStaffPhoto.jpg',
      name: 'Carly Trill',
      title: 'Community Coordinator'
    },
    {
      img: '/assets/images/people/staff/karenStaffPhoto.jpg',
      name: 'Karen Ubeda',
      title: 'Community Health Worker'
    }
  ];

  currentlyViewingBoardMember = null;

  boardMembers = [
    {
      img: '/assets/images/people/board-members/Margaret-Jahn.jpg',
      name: 'Margaret Jahn',
      title: 'President',
      blurb: 'Director of the Freehold Area Health Department, has served FAHD for 31 years. Health is her passion and she has built a career upon serving the community with her interests and expertise. She has achieved degrees in Biology, Environmental Science, and a Master’s in Public Health. Margy was an original member of the NCTH Steering Committee before it became a 501(c)3, and is chair of the Lead Poisoning Prevention Committee.'
    },
    {
      img: '/assets/images/people/board-members/Colleen-Nelson.jpg',
      name: 'Colleen Nelson',
      title: 'Vice President',
      blurb: 'Vice President of VNAHG Children & Family Health Institute, has served the VNAHG for over 20 years. She began her career as a Nurse, and since then has grown with her passions of community building and health equity. Colleen has achieved degrees in Nursing (BSN) from the University of Rhode Island, and a Master’s of Nursing Administration from Thomas Edison University. Colleen was an original member of the NCTH Steering Committee before if became a 501©3, and is chair of the Access to Healthy Food Committee.'
    },
    {
      img: '/assets/images/people/board-members/Jeff-Friedman.jpg',
      name: 'Jeff Friedman',
      title: 'Treasurer',
      blurb: 'Executive Director of Downtown Freehold and Art Gallery owner, unifies the businesses of Freehold NJ. Jeff is co-chair of the Access to Healthy Food committee.'
    },
    {
      img: '/assets/images/people/board-members/Lynn-Reich.jpg',
      name: 'Lynn Reich',
      title: 'Secretary',
      blurb: 'Current Vice Chair of the Freehold Borough Educational Foundation and former Art Teacher at Freehold Borough Public Schools, has been a staple to the Freehold Borough Community for many years. Her perspective as a resident, teacher, and volunteer of Freehold Borough has brought so much light to NCTH. Additionally, her altruistic spirit has been integral in the growth of NCTH.'
    },
    {
      img: '/assets/images/people/board-members/Rita-Dentino.jpg',
      name: 'Rita Dentino',
      title: 'Board Member',
      blurb: 'Executive Director of Casa Freehold, has executed her passions of community building and immigrant rights throughout her career. She has been a staple of the Greater Freehold community, serving as a key advocate for social justice. Her connection to the community and her expertise has been integral in the growth of NCTH. Rita achieved a Master’s Degree in Sociology from Boston University.'
    },
    // {
    //   img: '/assets/images/people/board-members/Angelica-Garcia.jpg',
    //   name: 'Angelica Garcia',
    //   title: 'Board Member',
    //   blurb: 'Health Educator at the Freehold Area Health Department and Coordinator of the Health Improvement Coalition of Monmouth County (HICMC), has served in a variety of roles in accordance with her passion for Public Health. Formerly, Angelica served as a Breast Cancer Prevention Coordinator and Health Educator for RWJ University Hospital and with Casa Freehold. Angelica achieved a Master’s of Public Health at Rutgers University. Angelica was an original member of the NCTH Steering Committee before if became a 501©3, and is chair of the Lead Poisoning Prevention Committee.'
    // },
    {
      img: '/assets/images/people/board-members/Ricky-Pierce.jpg',
      name: 'Reverend Robert D. (Ricky) Pierce, Sr.',
      title: 'Board Member',
      blurb: 'Currently serves as the Assistant Pastor of New Beginnings Agape Christian Center of Freehold, NJ. He also serves as the Chairman and Executive Director of the I Beseech Thee Community Development Corporation (IBTCDC). One of Ricky’s great passions is teaching and ministering the Word of God to inmates at the New Jersey State Prison in Trenton, NJ where he has served as a Religious Volunteer for the past eleven years. Rev. Pierce also serves as a member of the Monmouth County Human Services Advisory Council and the Monmouth County Human Relations Commission. He was educated in the Albany Public School System and attended the State University of New York at Albany and Baruch College in New York City where he majored in Accounting and Business Administration, and Nyack College where he majored in Church Administration. In 1997, he graduated class valedictorian from Monmouth Bible Institute, Farmingdale, NJ.'
    },
    // {
    //   img: '/assets/images/people/board-members/Gail-Reilly.jpg',
    //   name: 'Gail Reilly',
    //   title: 'Board Member',
    //   blurb: 'Chief Clinical and Quality Officer at VNACJ Community Health Center.'
    // },
    // {
    //   img: '/assets/images/people/board-members/Mary-Remhoff.jpg',
    //   name: 'Mary Remhoff',
    //   title: 'Board Member',
    //   blurb: 'Serves as APN Manager of Special Child Health at the VNA of Central Jersey. She has been with VNACJ for over 25 years. Prior, Mary was a Pediatric Oncology Nurse at the Children’s Hospital of Boston. Mary has combined her passions of family health and community building and executed them professionally, as well as in her service to NCTH. Mary was part of the original NCTH steering committee, prior to 501©3 status, and was trained as the Coalition Coach by NJHI. She is chair of the Intergenerational Committee.'
    // },
    {
      img: '/assets/images/people/board-members/Jay-Robinson.jpg',
      name: 'Jay Robinson',
      title: 'Board Member',
      blurb: 'Community Relations Coordinator at Centrastate Healthcare System, continues to share his passion in health equity.'
    },
    {
      img: '/assets/images/people/board-members/maricela-menina.png',
      name: 'Maricela Menina',
      title: 'Board Member',
      // blurb: 'Community Relations Coordinator at Centrastate Healthcare System, continues to share his passion in health equity.'
    },
    {
      img: '/assets/images/people/board-members/terry-shlimbaum.png',
      name: 'Terry Shlimbaum',
      title: 'Board Member',
      // blurb: 'Community Relations Coordinator at Centrastate Healthcare System, continues to share his passion in health equity.'
    },
    {
      img: '/assets/images/people/board-members/jean-holtz.png',
      name: 'Jean Holtz',
      title: 'Board Member',
      // blurb: 'Community Relations Coordinator at Centrastate Healthcare System, continues to share his passion in health equity.'
    },
    {
      img: '/assets/images/people/board-members/kim-simers.jpg',
      name: 'Kim Simers ',
      title: 'Board Member',
      // blurb: 'Community Relations Coordinator at Centrastate Healthcare System, continues to share his passion in health equity.'
    },
    {
      img: '/assets/images/people/board-members/placeholder.png',
      name: 'Annette Jordan',
      title: 'Board Member',
      // blurb: 'Community Relations Coordinator at Centrastate Healthcare System, continues to share his passion in health equity.'
    },
    {
      img: '/assets/images/people/board-members/placeholder.png',
      name: 'Joe Howe',
      title: 'Board Member',
      // blurb: 'Community Relations Coordinator at Centrastate Healthcare System, continues to share his passion in health equity.'
    },
    {
      img: '/assets/images/people/board-members/placeholder.png',
      name: 'Paula Taylor',
      title: 'Board Member',
      // blurb: 'Community Relations Coordinator at Centrastate Healthcare System, continues to share his passion in health equity.'
    }
    // {
    //   img: '/assets/images/people/board-members/Jenna-Sistad.jpg',
    //   name: 'Jenna Sistad',
    //   title: 'Board Member',
    //   blurb: 'Program Manager of the Monmouth County Council for Young Children (CCYC) at VNAHG. She has been serving the VNA for 5 years and has shed her expertise in program development; including growth of teams, community groups and local resources. Prior, Jenna was a Program Coordinator for The Family Resource Network and achieved a BA in Public Administration from Kean University. Jenna’s connectedness with the Freehold Community and her ambition to improve living.'
    // },
    // {
    //   img: '/assets/images/people/board-members/Rocco-Tomazic.jpg',
    //   name: 'Rocco Tomazic',
    //   title: 'Board Member',
    //   blurb: 'Superintendent in Freehold Borough Public Schools, New Jersey, serving in that position since 2013. Previously he was the Superintendent in Linden, New Jersey. During his 18 years in education he has served in various assignments as a teacher, vice principal, assistant superintendent and superintendent. He has been a college instructor at Rutgers University and the University of Maryland. Prior to his career in education he served in the U.S. Navy retiring as a Commander. He earned his educational doctorate at Rutgers University. He has jointly published a number of articles on suicide and the school environment. He served 20 years in the U.S. Navy as a Surface Officer.'
    // }
  ];

  partners = [
    {
      img: '/assets/images/partners/acelero.jpg',
      name: 'Acelero Learning Center',
      link: 'https://www.acelero.net/'
    },
    {
      img: '/assets/images/partners/advocare.jpg',
      name: 'Pediatrich Care',
      link: 'http://pediatrichealthpa.com/'
    },
    {
      img: '/assets/images/partners/carc.jpg',
      name: 'Community Affairs Resource Center (CARC)',
      link: 'https://carcnj.org/'
    },
    {
      img: '/assets/images/partners/casa.jpg',
      name: 'Casa Freehold',
      link: 'http://www.casafreehold.org/'
    },
    {
      img: '/assets/images/partners/ccyc.jpg',
      name: 'Monmouth County Council for Young Children',
      link: 'https://www.facebook.com/monmouthoceanccyc/'
    },
    {
      img: '/assets/images/partners/centrastate2.jpg',
      name: 'CentraState Healthcare System',
      link: 'https://www.centrastate.com/'
    },
    {
      img: '/assets/images/partners/church.jpg',
      name: 'Pentecostal Manantial de Vida Church',
      link: 'https://www.facebook.com/Iglesia-Pentecostal-Manantial-de-Vida-Freehold-NJ-109767410713289/?rf=113607285339833'
    },
    {
      img: '/assets/images/partners/cjfhc.jpg',
      name: 'Central Jersey Family Health Consortium',
      link: 'https://www.cjfhc.org/index.php/en/'
    },
    {
      img: '/assets/images/partners/countyMonmouth.jpg',
      name: 'Monmouth County Health Department'
    },
    {
      img: '/assets/images/partners/downtownFreehold.jpg',
      name: 'Downtown Freehold',
      link: 'https://downtownfreehold.com/'
    },
    {
      img: '/assets/images/partners/educationCommunityCenter.jpg',
      name: 'Court Street School',
      link: 'https://courtstreetschool.com/'
    },
    {
      img: '/assets/images/partners/freeholdBorough.jpg',
      name: 'Freehold Borough Schools',
      link: 'https://www.freeholdboro.k12.nj.us/'
    },
    {
      img: '/assets/images/partners/freeholdHealthDepartment.jpg',
      name: 'Freehold Area Health Department (FAHD)',
      link: 'http://twp.freehold.nj.us/health-department'
    },
    {
      img: '/assets/images/partners/freeholdOpenDoor.jpg',
      name: 'Freehold Area Open Door',
      link: 'https://www.freeholdareaopendoor.org/'
    },
    {
      img: '/assets/images/partners/freeholdPolice.jpg',
      name: 'Freehold Police Department',
      link: 'http://freeholdboroughnj.gov/police/index.html'
    },
    {
      img: '/assets/images/partners/fulfill.jpg',
      name: 'Fulfill',
      link: 'https://fulfillnj.org/'
    },
    {
      img: '/assets/images/partners/kiely.jpg',
      name: 'Kiely Properties',
      link: 'https://www.kielyproperties.com/'
    },
    {
      img: '/assets/images/partners/meetTheNeed.jpg',
      name: 'Meet The Need'
    },
    {
      img: '/assets/images/partners/newBeginnings.jpg',
      name: 'New Beginnings Agape Christian Center'
    },
    {
      img: '/assets/images/partners/rutgers.jpg',
      name: 'Rutgers University',
      link: 'https://www.rutgers.edu/'
    },
    {
      img: '/assets/images/partners/scan.jpg',
      name: 'SCAN NJ',
      link: 'https://scannj.org/programs/'
    },
    {
      img: '/assets/images/partners/snap.jpg',
      name: 'SNAP',
      link: 'https://www.fns.usda.gov/snap/supplemental-nutrition-assistance-program'
    },
    {
      img: '/assets/images/partners/snapEd.jpg',
      name: 'SNAP ED',
      link: 'https://snaped.fns.usda.gov/'
    },
    {
      img: '/assets/images/partners/vna.jpg',
      name: 'Visiting Nurse Association',
      link: 'https://vnahg.org/'
    },
    {
      img: '/assets/images/partners/wellnessCenter.jpg',
      name: 'Freehold Community Wellness Center'
    },
    {
      img: '/assets/images/partners/wic.jpg',
      name: 'Women Infants & Children (WIC)',
      link: 'https://www.state.nj.us/health/fhs/wic/'
    },
    {
      img: '/assets/images/partners/ymca.jpg',
      name: 'The Freehold Borough YMCA',
      link: 'https://ymcanj.org/locations/freehold-borough-ymca/freehold-borough-ymca-news-events/'
    }
  ];

  funders = [
    {
      img: '/assets/images/funders/nicholson.png',
    },
    {
      img: '/assets/images/funders/children.png',
    },
    {
      img: '/assets/images/funders/rwj.jpg',
    },
    {
      img: '/assets/images/funders/healthInitiatives.png',
    }
  ];

  constructor() { 
    this.partners.sort(function(a,b) {
      const firstName = a.name.toUpperCase();
      const secondName = b.name.toUpperCase();

      if (firstName < secondName) {
        return -1;
      } else if (firstName > secondName) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  ngOnInit() {
  }

  showBoardMemberModal(boardMember) {
    this.currentlyViewingBoardMember = boardMember;
    document.body.className = 'overflow-hidden';
  }

  closeBoardMemberModal() {
    this.currentlyViewingBoardMember = null;
    document.body.className = '';
  }
}
