import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-healthy-food',
  templateUrl: './healthy-food.component.html',
  styleUrls: ['./healthy-food.component.scss']
})
export class HealthyFoodComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
