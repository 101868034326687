import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { MissionComponent } from './components/mission/mission.component';
import { BikeSafetyComponent } from './components/bike-safety/bike-safety.component';
import { HealthyFoodComponent } from './components/healthy-food/healthy-food.component';
import { IntergenerationalComponent } from './components/intergenerational/intergenerational.component';
import { LeadPoisoningComponent } from './components/lead-poisoning/lead-poisoning.component';
import { WhatWeDoComponent } from './components/what-we-do/what-we-do.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'mission',
    component: MissionComponent
  },
  {
    path: 'programs/bikesafety',
    component: BikeSafetyComponent
  },
  {
    path: 'programs/healthyfood',
    component: HealthyFoodComponent
  },
  {
    path: 'programs/intergen',
    component: IntergenerationalComponent
  },
  {
    path: 'programs/leadpoison',
    component: LeadPoisoningComponent
  },
  {
    path: 'programs/whatwedo',
    component: WhatWeDoComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
