import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { SlickCarouselModule } from 'ngx-slick-carousel';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppRoutingModule } from './app-routing.module';

import { InViewportModule } from '@thisissoon/angular-inviewport';

import { ScrollSpyModule } from '@thisissoon/angular-scrollspy';

import { PreventDefaultDirective } from './directives/preventDefault.directive';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { LeadPoisoningComponent } from './components/lead-poisoning/lead-poisoning.component';
import { BikeSafetyComponent } from './components/bike-safety/bike-safety.component';
import { HealthyFoodComponent } from './components/healthy-food/healthy-food.component';
import { IntergenerationalComponent } from './components/intergenerational/intergenerational.component';
import { MissionComponent } from './components/mission/mission.component';
import { ContactComponent } from './components/contact/contact.component';
import { FooterComponent } from './components/footer/footer.component';
import { WhatWeDoComponent } from './components/what-we-do/what-we-do.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    AboutComponent,
    LeadPoisoningComponent,
    BikeSafetyComponent,
    HealthyFoodComponent,
    IntergenerationalComponent,
    MissionComponent,
    ContactComponent,
    FooterComponent,
    WhatWeDoComponent,
    PreventDefaultDirective
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    SlickCarouselModule,
    FontAwesomeModule,
    InViewportModule,
    ScrollSpyModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }