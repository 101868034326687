import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-intergenerational',
  templateUrl: './intergenerational.component.html',
  styleUrls: ['./intergenerational.component.scss']
})
export class IntergenerationalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
