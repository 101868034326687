import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  shouldDisplayLanguageSelectors = false;

  constructor() {}

  ngOnInit() {
    setTimeout(() => {
      this.shouldDisplayLanguageSelectors = true;
    }, 1000 * 2);
  }

  translateToSpanish() {
    window['translateToSpanish']();
  }

  translateToEnglish() {
    window['translateToEnglish']();
  }
}