import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-what-we-do',
  templateUrl: './what-we-do.component.html',
  styleUrls: ['./what-we-do.component.scss']
})
export class WhatWeDoComponent implements OnInit {
  offset = 90;

  constructor() { }

  ngOnInit() {
  }

  scrollToElement(event: MouseEvent) {
    event.preventDefault();
    const elementID = (event.srcElement as HTMLElement).getAttribute('href');
    const scrollElement: HTMLElement = document.querySelector(elementID);
    const scrollTop = scrollElement.offsetTop;
    document.body.scrollTo({ behavior: 'smooth', top: scrollTop - this.offset });
  }
}